@font-face {
	font-family: 'bgBlack';
	src: url('../../dist/fonts/font/Brandon_blk.eot'); 

	src: url('../../dist/fonts/font/Brandon_blk.eot?#iefix') format('embedded-opentype'), 
	
	url('../../dist/fonts/font/Brandon_blk.woff') format('woff'), 
	
	url('../../dist/fonts/font/Brandon_blk.svg#Brandon_blk') format('svg'), 

	url('../../dist/fonts/font/Brandon_blk.ttf') format('truetype');
	
	font-weight: normal;
	font-style: normal;
	font-smooth: always;
}

@font-face {
	font-family: 'bgBold';
	src: url('../../dist/fonts/font/Brandon_bld.eot'); 

	src: url('../../dist/fonts/font/Brandon_bld.eot?#iefix') format('embedded-opentype'), 
	
	url('../../dist/fonts/font/Brandon_bld.woff') format('woff'), 
	
	url('../../dist/fonts/font/Brandon_bld.svg#Brandon_bld') format('svg'), 

	url('../../dist/fonts/font/Brandon_bld.ttf') format('truetype');
	
	font-weight: normal;
	font-style: normal;
	font-smooth: always;
}

@font-face {
	font-family: 'bgLight';
	src: url('../../dist/fonts/font/Brandon_light.eot'); 

	src: url('../../dist/fonts/font/Brandon_light.eot?#iefix') format('embedded-opentype'), 
	
	url('../../dist/fonts/font/Brandon_light.woff') format('woff'), 
	
	url('../../dist/fonts/font/Brandon_light.svg#Brandon_light') format('svg'), 

	url('../../dist/fonts/font/Brandon_light.ttf') format('truetype');
	
	font-weight: normal;
	font-style: normal;
	font-smooth: always;
}

@font-face {
	font-family: 'bgThinIt';
	src: url('../../dist/fonts/font/Brandon_thin_it.eot'); 

	src: url('../../dist/fonts/font/Brandon_thin_it.eot?#iefix') format('embedded-opentype'), 
	
	url('../../dist/fonts/font/Brandon_thin_it.woff') format('woff'), 
	
	url('../../dist/fonts/font/Brandon_thin_it.svg#Brandon_thin_it') format('svg'), 

	url('../../dist/fonts/font/Brandon_thin_it.ttf') format('truetype');
	
	font-weight: normal;
	font-style: normal;
	font-smooth: always;
}

@font-face {
	font-family: 'bgRegIt';
	src: url('../../dist/fonts/font/Brandon_reg_it.eot'); 

	src: url('../../dist/fonts/font/Brandon_reg_it.eot?#iefix') format('embedded-opentype'), 
	
	url('../../dist/fonts/font/Brandon_reg_it.woff') format('woff'), 
	
	url('../../dist/fonts/font/Brandon_reg_it.svg#Brandon_reg_it') format('svg'), 

	url('../../dist/fonts/font/Brandon_reg_it.ttf') format('truetype');
	
	font-weight: normal;
	font-style: normal;
	font-smooth: always;
}

@font-face {
	font-family: 'bgBoldIt';
	src: url('../../dist/fonts/font/Brandon_bld_it.eot'); 

	src: url('../../dist/fonts/font/Brandon_bld_it.eot?#iefix') format('embedded-opentype'), 
	
	url('../../dist/fonts/font/Brandon_bld_it.woff') format('woff'), 
	
	url('../../dist/fonts/font/Brandon_bld_it.svg#Brandon_bld_it') format('svg'), 

	url('../../dist/fonts/font/Brandon_bld_it.ttf') format('truetype');
	
	font-weight: normal;
	font-style: normal;
	font-smooth: always;
}

@font-face {
	font-family: 'bgMed';
	src: url('../../dist/fonts/font/Brandon_med.eot'); 

	src: url('../../dist/fonts/font/Brandon_med.eot?#iefix') format('embedded-opentype'), 
	
	url('../../dist/fonts/font/Brandon_med.woff') format('woff'), 
	
	url('../../dist/fonts/font/Brandon_med.svg#Brandon_med') format('svg'), 

	url('../../dist/fonts/font/Brandon_med.ttf') format('truetype');
	
	font-weight: normal;
	font-style: normal;
	font-smooth: always;
}