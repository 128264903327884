/**
    Deniz Dalkılıç and İslam Özbek
 */
@import "fonts";


* {
  font-family: 'bgMed',arial,helvetica,sans-serif;
}

[ng\:cloak], [ng-cloak], [data-ng-cloak], [x-ng-cloak], .ng-cloak, .x-ng-cloak {
  display: none !important;
}

body {
  background: #030303 url(../../dist/images/anasayfa.png) no-repeat;
  background-size: cover;
  background-attachment: fixed;
  background-position: center;
}

.main-content {
  
  &.close-menu{

    .loading {
      width: calc(100% - 80px);

      @include responsive(xs){
        width: 100%;
      }
    }

    .left-menu {
      width: 80px;
      .menu-head {
        .menu-button {
          span {
            width: 40px !important;
          }
        }
      }
      .menu {
        display: none;
      }
    }

    .page-content {
      width: calc(100% - 80px);
      @include responsive(xs){
        width: 100%;
        padding-top: 68px;
      }

      .project-detail {
        .wrapper {
          width: calc(100% - 80px);
        }
      }
    }
  
    .footer {
      width: calc(100% - 80px);
    }
  }
}

.menu-button {
  cursor: pointer;
  @include easing();
  width: 25px;
  margin: 0 auto;
  position: relative;
  height: 20px;


  &.close-button {
    span {
      opacity: 0;
    }
    span:first-child {
      opacity: 1;
      transform: rotate(45deg);
      -webkit-transform-origin: left center;
      -moz-transform-origin: left center;
      -o-transform-origin: left center;
      transform-origin: left center;
      top: -2px;
      left: 2px;
    }
    span:last-child {
      opacity: 1;
      transform: rotate(-45deg);
      -webkit-transform-origin: left center;
      -moz-transform-origin: left center;
      -o-transform-origin: left center;
      transform-origin: left center;
      left: 2px;
      top: 16px;
    }
  }

  span {
    width: 100%;
    height: 1px;
    background-color: #fff;
    display: block;
    position: absolute;
    @include easing();
    left:0;

    &:first-child {
      top: 0px;
    }
    &:nth-child(2){
      top: 8px;
    }
    &:last-child {
      top: 16px;
    }
    
  }
}

.left-menu {
  width: $menu-width;
  position: fixed;
  top: 0;
  left: 0;
  min-height: 100%;
  background-color: #000000;
  @include easing(.2s);
  z-index: 999999999;

  &.hover {
    width: 160px;

    @include responsive(xs){
      height: 100%;
    }

    .menu {
      display: block;
    }

    .bottom-detail {
      display: block;
    }

    .menu-center-logo {
      .small-logo {
        display: none;
      }
    }

    &.show-menu {
      .menu {
        opacity: 1;
      }
      .bottom-detail {
        opacity: 1;
      }
  
      .menu-button {
        opacity: 0;

        @include responsive(xs){
          opacity: 1;
        }
      }
  
      .menu-center-logo {
        .big-logo {
          display: inline-block;
        }
      }
    }
  }

  &.fixed {
    position: fixed;
    top:auto;
    bottom:0;

    @include responsive(xs){
      top:0;
      bottom:auto;
    }
  }

  @include responsive(xs){
    width: 100% !important; 
    z-index: 999999;
    background-color: #030303;
    min-height: auto;
    height: 68px;
    text-align: center;
  }


  .menu-head {
    padding: 20px 10px 10px 10px;

    @include responsive(xs){
      position: absolute;
      left: 11px;
      top: 6px;
    }

  }

  .menu-center-logo {
    margin-top: 30px;
    width: 100%;
    text-align: center;

    @include responsive(xs){
      display: inline-block;
      width: auto;
      margin-top: 10px;

      a {
        img {
          height: 42px !important;
          width: auto !important;
        }
      }
    }

    a {
      text-align: center;
      .small-logo {
        width: 60%;
        display: inline-block;
      }

      .big-logo {
        display: none;
        width: 70%;
      }
    }
  }

  .menu {
    margin: 50px 0;
    display: none;
    opacity: 0;
    @include easing();

    @include responsive(xs){
      position: fixed;
      width: 100%;
      background: #191818;
      top: 68px;
      margin: 0;
      height: 100%;
      padding-top: 50px;
    }

    ul {
      li {
        padding: 8px 15px;
        margin-bottom: 2px;
        position: relative;
        overflow: hidden;
        @include easing();
        @include responsive(xs){
          text-align: center;
        }

        li {
          @include responsive(xs){
            padding: 2px 10px !important;
          } 
        } 

        &:before {
          content: '';
          position: absolute;
          top: 0;
          right: -50px;
          left: 0;
          border-right: 30px solid transparent;
          border-bottom: 32px solid #ffffff;
          -webkit-transform: translateX(-100%);
          transform: translateX(-100%);
          @include easing();
        }
        &:hover {
          &:before {
            -webkit-transform: translateX(0);
            transform: translateX(0);
          }

          a {
            span {
              color:#000000;
            }
          }
        }

        a {
          display: block;
          position: relative;
          @include easing();


          span {
            color: #ffffff;
            font-size: 13px;
            font-family: 'bgBold',arial,helvetica,sans-serif;
            font-weight: 500;
            @include easing();
            @include responsive(xs){
              font-size: 18px;
            }

            &:after {
              content: "";
              width: 0;
              height: 2px;
              background-color: $active;
              position: absolute;
              top: 9px;
              right: 133%;
              @include easing();
            }
        }
        }

        &.active {
          background-color: $white;

          >a {
            span {
              color: $black;
              position: relative;

              @include responsive(xs){
                position: relative;
                z-index: 9999;
                padding: 0 22px;
              }
            }
          }

        }

        ul {
          width: 100%;
          margin-top: 10px;
          @include easing();

          /* ng-hide, ng-show  */

          &.ng-hide-add,
          &.ng-hide-remove {
              max-height: 300px;
              transition-property: max-height, opacity;
              transition-duration: 250ms;
          }

          &.ng-hide {
              max-height: 0;
              opacity: 0;
          }

          li {
            margin-bottom: 0;
            border:0 !important;
            padding-right: 0;

            a {
              span {
                font-size: 11px;
                font-weight: normal;
                text-transform: uppercase;
                &:after {
                  width: 0 !important; 
                }
              }
            }
          }
        }
      }
    }
  }

  .bottom-detail {
    position: absolute;
    bottom: 0;
    display: none;
    opacity: 0;
    left:0;
    padding: 10px 10px 15px 10px;
    width: 100%;


    .social {
      margin-top: 12px;
      margin-bottom: 10px;
      text-align: center;

      a {
        width: 25px;
        height: 25px;
        border-radius: 3px;
        color: #ffffff;
        font-size: 14px;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        margin-left: 5px;
        @include easing(.3s, background);

        &.vimeo {
          background-color: #69b4e5;
        }

        &.youtube {
          background-color: #e02f2f;
        }

        &.facebook {
          background-color: #475993;
        }

        &:hover {
          background-color: $active;
        }
      }
    }

    .langs {
      text-align: center;

      @include responsive(xs){
        left:auto;
        bottom:auto;
        top:24px;
        right: 20px;
      }

      a{
        color: #fff;
        font-size: 14px;
      }

      i {
        color:#ffffff;
        font-size: 18px;
        display: inline-block !important;
      }
    }
  }

}

.page-content {
  width: calc(100% - #{$menu-width});
  float: right;
  //@include easing();

  @include responsive(xs){
    width: 100%; 
  }

  &.detail {
    background-color: rgba(0, 0, 0, .8);

    @include responsive(sm){background-color: #030303;}
    @include responsive(xs){background-color: #030303;}
  }

  &.full-wrapper {
    z-index: 9999;
    position: relative;

    .project-detail {
      .wrapper {
        width: 100%;
        height: 100% !important;
        z-index: 999;
      }
    }
  }

  .center-videos {
    position: relative;
    overflow: hidden;

    .video-on-background {
      position: absolute;
      top:0;
      left:0;
      width: 100%; 
      height: 100%;
      background: url("../../dist/images/video_background.png") no-repeat;
      z-index: 999;
      background-position: center center;
      background-size: cover;
      opacity: .8;

      @include responsive(xs){
        background-image: url(../../dist/images/video_background_mobil.png);
        background-color: white;
      } 
    }

    video {
      position: absolute;
      top: 50%;
      left: 50%;
      min-width: 130%;
      min-height: 130%;
      width: auto;
      height: auto;
      z-index: -100;
      transform: translateX(-50%) translateY(-50%);
      transition: 1s opacity;
      z-index: 1;

      @include responsive(xs){
        display: none;
      }
    }
  }

  .row.basic {
    margin: 0;
  }


  .parallax{
    position: relative;

    .parallax-wrapper {
      position: fixed;
      width: 100%;
      top:0;
      overflow: hidden;

      .parallax-zoom-image {
        transform:scale(1.2);
        width: 100%;
        position: absolute;
        height: 100%;
        object-fit: cover;

        @include responsive(sm){
          max-width: 100%;
          height: 100%;
        }
        @include responsive(xs){
          max-width: 100%;
          height: 100%;
        }
      }
    }

      
    .parallax-detail {
      position: absolute;
      width: 100%;
      top:0;
      background: $white;
    }
  }

  .projects {

    &.full-detail{
      .parallax-full-detail {
        align-items: flex-start;
        padding-top: 10%;
      } 

      .wrapper-list {
        position: absolute;
        bottom:100px;
        left: 0;
        right: 0;
        margin: auto;
        width: 100%; 

        .owl-controls {
          .owl-nav {
            text-align: center;
            .owl-prev,
            .owl-next {
              font-size: 24px;
              color:$white;
              display: inline-block;
              margin: 20px 4px;
              @include easing();

              &:hover{
                color:#a9a7a7;
              }
            }
          }
        }

        .owl-item {
          opacity: .3;
          @include easing();

          &.center {
            opacity: 1;
          }
        }
        .box {
          height: 17vw;
        }
      }
    }

    .parallax-detail {
      padding: 2px;

      &.full-project {
        padding: 0;
        padding-top: 15px;
      }
    }

    .parallax-zoom-image {
      opacity: .3;
    }

    .parallax-full-detail {
      position: absolute;
      top: 0;
      width: 100%;
      height:100%;
      text-align: center;
      z-index: 999;
      font-family: 'bgBold', sans-serif;
      padding: 7px;
      display: flex;
      justify-content: center;
      align-items: center;

      .center {
        width: 100%;
      }

      .detail-text {
        .back-category {
          color:$white;
          font-size: 16px;
          display: block;
          margin-bottom: 10px;
          margin-left: -20px;
          
          span {
            margin-left: 5px;
          }
        }
        h2 {
          font-size: 40px;
          color:$white;
          margin-bottom: 20px;
        }
        p {
          font-size: 14px;
          color:$white;
        }
      }

      .parallax-category {
        margin-top: 70px;
        width: 100%;
        text-align: center;
        padding: 10px;

        li {
          display: inline-block;
          margin: 0 5px;

          @include responsive(sm){
            margin:5px 5px;
          }

          @include responsive(xs){
            margin: 2% 2%;
            width: 45%;
          }

          &:hover,
          &.active {
            a {
              background-color: $white;
              color:$black;
            }
          }

          a {
            display: block;
            font-size: 14px;
            padding: 10px 20px;
            border: solid 1px #ffffff;
            color: #ffffff;
            font-family: 'roboto', sans-serif;
            font-weight: 500;
            @include easing();
            border-radius: 2em; 
            min-width: 125px;
          }
        }
      }

      .down-icon {
        position: absolute;
        bottom:30px;
        width: 100%;
        text-align: center;
        left:0;
        i {
          color:$white;
          font-size: 30px;
          animation: 1s listProjectArrow infinite;
          display: block;
        }
      }
    }


    .full-top-project {
      width: 100%;
      background: $black;

      .images {
        position: absolute;
        width: 100%;
        height: 100%;
        overflow: hidden;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    .directors {
      background-color:rgba(0, 0, 0, 0.45);
      padding: 20px;
      span {
        color:$white;
        font-size: 20px;
        font-weight: bold;
      }
    } 

    div[class*='col-']{
      padding: 0;
    }

    .works-project {
      .box {
        min-height: auto !important;
      }
    }
    .full-project:not(.works-project) {
      .box {
        margin: 0;
        .box-cont {
          height: 100%;
          left:0;
          display: flex;
          justify-content: center;
          align-items: center;

          h2 {
            font-size: 40px;

            @include responsive(sm){
              font-size: 30px;
            }
            @include responsive(xs){
              font-size: 25px;
            }
          }

          p {
            font-size: 18px;
          }
        }

        .images {
          &:before {
            background:rgba(0, 0, 0, 0.2);
          }
        }
      }
    }

    .box {
      height: 24vw;
      padding: 0;
      position: relative;
      overflow: hidden;
      margin: 2px;
      z-index: 2; 

      &.bigList {
        width: 100% !important;
        flex: 0 0 100%;
        max-width: 100%;
        height:30vw;
      }

      @include responsive(sm){
        min-height: 20vw !important;
      }

      @include responsive(xs){
        min-height: 300px !important;
      }

      .images {
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;

        &:after, &:before{
          content:"";
          position: absolute;
          width: 100%;
          height: 100%;
          top:0;
          left:0;
          @include easing();
          z-index: 9999;
        }

        &:after {
          background-image: url(../../dist/images/box_title_shadow.png);
          background-repeat: no-repeat;
          background-size: 100% 51%;
          background-position: bottom;
          @include easing();
        }

        img {
          width: 100%;
          min-height: 100%;
          @include easing();
        }
      }

      &:hover {
        // .images {
        //   img {
        //     transform: scale(1.1);
        //     -moz-transform: scale(1.1);
        //     -ms-transform: scale(1.1);
        //   }

        //   &:after {
        //     opacity: 0;
        //   }
        //   &:before {
        //     background:rgba(0, 0, 0, 0.7);
        //   }
        // }
      }


      .box-cont {
        width: 100%;
        height: auto;
        position: absolute;
        left: 0;
        bottom:0;
        z-index: 2;
        color: #ffffff;
        @include easing(); 
        text-align: center;
        z-index: 9999;
        padding: 10px 10px;
        

        h2 {
          font-size: 20px;
          font-family: 'bgMed', sans-serif; 
        }

        p {
          font-size: 18px;
          margin-top: 3px;
        }

      }

      .hover {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        z-index: 4;
        background: rgba(0, 0, 0, .6);
        display: flex;
        align-items: center;
        padding: 0 30px;
        opacity: 0;
        @include easing();

        h1 {
          font-size: 26px;
          color: $active;
          font-weight: 300;
          display: none;
          font-family: 'bgReg', sans-serif;
        }
        p {
          font-weight: 100;
          font-size: 14px;
          color: $white;
          margin-top: 10px;
          display: none;
          height: 53px;
        }
        .btn {
          padding: 11px 35px;
          border: solid 1px $active;
          color: $active;
          border-radius: 4px;
          background-color: transparent;
          margin-top: 20px;
          padding-left: 27px;
          @include easing();

          i {
            margin-right: 10px;
          }

          &:hover {
            background-color: $active;
            color: #000;
          }
        }
      }
    }
  }

  .project-detail {
    overflow: hidden;
    display: none;

    @include responsive(xs){
        background: #030303 !important;
    }

    &.active {
      display: block;
    }

    .wrapper {
      width: calc(100% - #{$menu-width});
      text-align: center;
      z-index: 3;
      position: fixed;
      top: 0;
      right: 0;
      @include easing();
      height: calc(100% - 250px);

      @include responsive(xs){
        height: 430px !important;
        position: relative;
        width: 100% !important;
        margin-top: 68px;
      }

      .owl-carousel {
        .owl-item {
          //width: 100% !important;
        }
      }

      .back-close {
        position: absolute;
        right: 0;
        padding: 10px;
        color:#ffffff;
        background-color: rgba(0,0,0,0.5);
        top:0;
        z-index: 99999;
        font-size: 22px;
      }

      .right-button {
        position: absolute;
        right: 0;
        bottom: 0;
        z-index: 3;

        .btn {
          border-radius: 0;
          background-color: #040505;
          color: $white;
          @include easing();

          &:hover,
          &.active {
            background-color: $active; 
          }
        }
      }

      .owl-controls {
        display: inline-block;
        padding: 0 20px;
        position: relative;
        text-align: center;
        bottom: 37px; // 50px

        .owl-nav {
          width: 100%;
          position: absolute;
          left: 0;

          @include responsive(xs){
            display: none;
          }

          div {
            font-size: 30px;
            color: $white;
            position: absolute;
            left: 0;

            &.owl-next {
              left: auto;
              right: 0;
            }
          }
        }

        .owl-dots {
          display: inline-block;
          margin-top: 9px;
          .owl-dot {
            display: inline-block;

            &.active {
              span {
                background-color: $white;
              }
            }

            span {
              width: 15px;
              height: 15px;
              border: solid 2px $white;
              border-radius: 50%;
              display: block;
              margin: 0 2px;

              @include responsive(xs){
                width: 10px;
                height: 10px;
              }
            }
          }
        }
      }

      .item {
        width: 100%;
        height: 100%;
        background-color: #000;

        iframe{
          width: 100%;
          height: calc(100% - 45px);
        }
        

        &.video {

          .hide-video {
            display: none;
          }

          .video-option {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 100px;
            margin: auto;
            z-index: 999999;
            right: 0;

            .btn {
              padding: 8px 36px; 
              margin-top: 13px;
              border-radius: 30px;
              background: transparent;
              border-color: #fff;
              font-size: 13px;
              @include easing();

              &:hover {
                background-color: #fff;
                color:#000000;
              }

              @include responsive(sm){
                padding: 5px 27px;
                margin-top: 27px;
              }

              @include responsive(xs){
                padding: 5px 27px;
                margin-top: 27px;
              }
            }

            .title {
              font-size: 30px;
              color: #fff;
              display: block;
              font-weight: 500;

              @include responsive(xs){
                font-size: 22px;
              }
            }
          }

          .images {
            span.player {
              position: absolute;
              width: 70px;
              height: 70px;
              border: solid 2px $white;
              border-radius: 50%;
              top: 0;
              left: 0;
              bottom: 0;
              right: 0;
              margin: auto;
              z-index: 999;
              // box-shadow: 0 1px 5px $white;
              // text-shadow: 0 1px 5px $white;
              display: flex;
              justify-content: center;
              align-items: center;
              font-size: 25px;
              color: #ffffff;
              cursor: pointer;
              @include easing();
              padding-left: 9px;

              @include responsive(xs){
                width: 70px;
                height: 70px;
                font-size: 26px;
              }

              &:after {
                content: "\f04b";
                font-family: FontAwesome;
              }

              &:hover {
                border-color: $active;
                color: $active;
                // box-shadow: 0 1px 5px $active;
                // text-shadow: 0 1px 5px $active;

              }
            }
            img {
              opacity: .5;
              min-height: 100%;
              width: 100%;
              object-fit: cover;

              @include responsive(xs){
                width: 100%;
                height: auto;
                min-height: auto;
              }
            }
          }
        }

        .images {
          width: 100%;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          position: relative;
          overflow: hidden;
        }
      }
    }
    .detail-content {
      position: relative;
      background: #ffffff;
      min-height: 250px;
      width: 100%;
      z-index: 4;
      padding: 30px;
      padding-left: 90px;
      color: $black;
      font-size: 14px;
      font-weight: 300;
      font-family: 'Roboto', sans-serif;


      @include responsive(xs){
        margin-top: 0 !important;
        text-align: center;
        padding: 20px 30px;
      }

      .head-column{
        text-align: center;
        span, strong, p {
          color:$black !important;
          font-family: 'Roboto', sans-serif;
        }

  
        h2.title {
          font-size: 29px;
          font-weight: 300;
          color: #191919;
          font-family: 'bgMed', sans-serif;
  
          @include responsive(xs){
            font-size: 21px;
            margin-bottom: 20px;
          }
        }

        .back-arrow {
          position: absolute;
          left: 16px;
          top: 17px;
          color: #9e9364;
          font-size: 23px;
  
          @include responsive(xs){
            display: none;
          }
        }
  
        .breadcrump {
          display: inline-block;
          width: 100%;
          margin-top: 12px;
  
          @include responsive(xs){
            margin-top: 10px;
            display: none;
          }
  
          li {
            display: inline-block;
            margin-right: 10px;
            a {
              font-size: 13px;;
              color: #7d7d7d;
              text-transform: capitalize;
  
              @include responsive(xs){
                font-size: 13px;
              }
  
              &:after {
                content: "\f105";
                font-family: FontAwesome;
                margin-left: 10px;
              }
            }
            span {
              font-size: 11px;
              color: #9a9696 !important;
            }
          }
        }

        .social {

          @include responsive(xs){
            position: relative;
            right: auto;
            top:auto;
          }

          a {
            width: 25px;
            height: 25px;
            border-radius: 3px;
            color: #ffffff;
            font-size: 14px;
            display: inline-flex;
            justify-content: center;
            align-items: center;
            margin-left: 5px;
            @include easing();
      
            &.vimeo {
              background-color: #69b4e5;
            }
      
            &.youtube {
              background-color: #e02f2f;
            }
      
            &.facebook {
              background-color: #475993;
            }
      
            &:hover {
              background-color: $active;
            }
          }
        }
      }

      .content-column {
        margin: 0 auto;
        margin-top: 30px;
        width: 70%;
        text-align: center;

        @include responsive(sm){
          width: 100%;
        }

        @include responsive(xs){
          width: 100%;
        }

        img {
          @include responsive(xs){
            width: 100% !important;
            height: auto !important;
          }
        }

        span, strong, p {
          color:$black !important;
          font-family: 'Roboto', sans-serif;
        }
      }

      
    }
  }

  .about-us, .directors {
    position: relative;
    width: 100%;
    height: 100%;
    
    .full-text {
      position: absolute;
      height: 100%;
      width: 30%;
      min-width: 273px;
      background-color: $white;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 20px;

      @include responsive(xs){
        width: 100%;
      }

      .center {
        margin-top: -40px;
        h1 {
          font-size: 25px;
          font-family: 'bgBold', sans-serif;
          margin-bottom: 15px;
        }
        h4 {
          font-family: 'bgMed', sans-serif;
          font-size: 15px;
          color: #797676;
          margin-bottom: 30px;
        }
        .text {
          font-size: 14px;
          font-family: 'bgLight', sans-serif;
          color:$black;
          word-spacing: 2px;

          p, span {
            color:#989595 !important;
            font-family: 'bgLight', sans-serif;
          }
        }
      }

      .slider-arrow {
        position: absolute;
        bottom:30px;
        left:20px;

        @include responsive(xs){
          display: none;
        }

        a {
          width: 40px;
          height: 40px;
          border:solid 2px $active;
          display: inline-block;
          @include borderRadius(50%);
          display: inline-flex;
          justify-content: center;
          align-items: center;
          color:$active;
          margin-right: 10px;

          &:hover {
            border-color:lighten($active, 10%);
            color:ighten($active, 10%);
            @include easing();
          }
        }
      }
    }

    .full-slider {
      @include responsive(xs){
        display: none;
      }
    }

    .full-slider, .right-projects {
      position: absolute;
      width: 70%;
      height: 100%;
      top:0;
      right: 0;

      @include responsive(sm){
        width: calc(100% - 273px);
      }


      @include responsive(xs){
        width: 100%;
        position: relative;
      }

      .item {
        position: absolute;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;

        img {
          height: 100%;
          width: auto;
        }
      }
    }
  }

  .directors {

    @include responsive(xs){
      margin-top: 68px;
    }
    .full-text {
      @include responsive(xs){
        display: none;
      }
      .list-items {
        margin-top: 30px;
        ul {
          li {
            list-style-type: disc;
            color: #9a9999;
            margin-left: 19px;
            font-size: 12px;
            @include easing();

            &:hover, 
            &.active {
              color:$black;
              a {
                color:$black;
              }
            }
            a {
              font-size: 16px;
              color: #9a9999;
              margin-top: 13px;
              display: block;
              @include easing();
            }
          }
        }
      }
    }

    .director-menu-responsive {
      width: 100%;
      background-color: $white;
      display: none;

      @include responsive(sm){
        display: block;
      }

      @include responsive(xs){
        display: block;
      }

      .head {
        text-align: center;
        padding: 20px;

        .menu-button {
          display: inline-block;
          margin-bottom: -4px;
          margin-right: 5px;
          span {
            background-color:$black;
            &:last-child {
              margin-bottom: 0;
            }
          }
        }

        h1 {
          display: inline-block;
          font-size: 18px;
          font-family: 'bgBold', sans-serif;
        }
      }

      .list-items {
        display: none;
        background: #f1f1f1;
        text-align: center;
        width: 100%;
        padding-bottom: 15px;
        overflow: hidden;
        ul {
          li {
            color: #9a9999;
            @include easing();

            &:hover, 
            &.active {
              color:$black;
              a {
                color:$black;
              }
            }
            a {
              @include easing();
              font-size: 13px;
              color: #9a9999;
              margin-top: 13px;
              display: block;
            }
          }
        }
      }
    }
    .right-projects {
      .full-image { 
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        width: 100%;
        height: 100%;

        @include responsive(sm){
          display: block;
        }
        @include responsive(xs){
          display: block;
        }

        img {
          width: 100%;
        }
      }
      .projects {
        overflow-y: auto;
        height: 100%;
      }
    }
  }

  .contact {
    width: 100%;

    @include responsive(xs){
      margin-top: 68px;
    }

    .cols {
      width: 33.33%;
      height: 100%;
      float: left;

      &.maps-direction {
        position: relative;
        @include responsive(sm){
          display: none;
        }
        @include responsive(xs){
          display: none;
        }

        &:hover {
          &:after {
            opacity: 0;
            visibility: hidden;
          }
        }


        &:after {
          content:"";
          width: 100%;
          height: 100%;
          position: absolute;
          top:0;
          left:0;
          background-color: rgba(0, 0, 0, .5);
          opacity: 1;
          @include easing();
        }
        div.maps-item {
          width: 100%;
          height: 100%;
        }
      }

      &.address {
        @include responsive(sm){width: 100%;}
        @include responsive(xs){width: 100%;}
        .item {
          position: relative;
          height: 50%;
          background-color: #030303;
          padding: 60px 40px;


          @include responsive(sm){height: auto;}
          @include responsive(xs){height: auto;padding: 30px;}
          
          &:after {
            content: "";
            position: absolute;
            left: -80px;
            top:50px;
            border:solid 40px transparent;
            border-color:transparent #030303 transparent transparent;
            z-index: 999;

            @include responsive(sm){content:initial;}
            @include responsive(xs){content:initial;}
          }

          &.first {
            background-color: white;
            &:after {
              border-color:transparent transparent transparent white;
              right: -80px;
              left:auto;
            }
            h2 {
              color:#030303; 
            }

            .address-list {
              label {
                color:#030303;
              }

              span {
                color:#030303;
              }
            }
          }

          h2 {
            font-size: 30px;
            font-weight: bold;
            color:white;
          }

          .address-list {
            margin-top: 40px;

            label {
              width: 100%;
              margin-bottom: 20px;
              font-size: 14px;
              color:white;
              i {
                width: 25px;
                float: left;
                text-align: center;
              }

              a{
                span {
                  color:#ffffff;
                }
              }

              span {
                width: calc(100% - 25px);
                float: left;
                font-weight: 500;
                padding-left: 15px;
              }
            }
          }

          .maps-in {
            display: none;
            width: 100%;
            height: 250px;
            margin-top: 20px;

            div.maps-item {
              width: 100%;
              height: 100%;
            }

            @include responsive(sm){
              display: block;
            }
            @include responsive(xs){
              display: block;
            }
          }
        }
      }
    }
  }

}


.loading {
  position: fixed;
  width: calc(100% - #{$menu-width});
  height: 100%;
  z-index: 9999;
  background-color: #000000;
  display: flex;
  top:0;
  right: 0;
  justify-content: center;
  align-items: center;

  @include responsive(xs){
    width: 100%;
  }

  &.relative {
    position: absolute;
    width: 100%;
  }

  .snippet {
    .top-images {
      border-radius: 50%;
      background: #fff url(../../dist/images/simple_logo_back.png) no-repeat center;
      width: 150px;
      height: 150px;
      box-shadow: 0 0 0 0 rgba(#fff, .5);
      animation: loadingCircle 1.5s infinite;
      -moz-animation: loadingCircle 1.5s infinite;
      -webkit-animation: loadingCircle 1.5s infinite;
      background-size:60%;
    }
  }
}


@keyframes loadingCircle {
  0% {
    -moz-transform: scale(0.9);
    -ms-transform: scale(0.9);
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
  }
  70% {
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -webkit-transform: scale(1);
    transform: scale(1);
    box-shadow: 0 0 0 50px rgba(90, 153, 212, 0);
  }
  100% {
    -moz-transform: scale(0.9);
    -ms-transform: scale(0.9);
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
    box-shadow: 0 0 0 0 rgba(90, 153, 212, 0);
  }
}


@keyframes listProjectArrow {
  0% {
    margin-bottom: 0px;
  }
  50% {
    margin-bottom: 10px;
  }
  100% {
    margin-bottom: 0px;
  }
}

@keyframes effect_dylan {
  50% {
    transform: scale(1.5, 1.5);
    opacity: 0;
  }
  99% {
    transform: scale(0.001, 0.001);
    opacity: 0;
  }
  100% {
    transform: scale(0.001, 0.001);
    opacity: 1;
  }
}
