
// standard variable
$full : 100%;


// space
$space : 1rem;

// responsive media
$media-small         : 768px;
$media-medium        : 992px;
$media-large         : 1200px;
$media-extra         : 1367px;


// fonts
$font : 'Roboto';
$font-style : sans-serif;


$background : #f1f1f1;

// colors
$white : #ffffff;

$active : #f9e79f;

$menu-width: 50px;

$black : #000000;
