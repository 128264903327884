/*
popup style
 */
 .popup {
    position: fixed;
    top: 0;
    left: 0;
    width: $full;
    height: $full;
    overflow-y: auto;
    overflow-x: auto;
    background: rgba(0, 0, 0, 0.70);
    z-index: 999999999999;
    display: none;
  
    &.show {
      display: block;
    }
  
    &.full {
      .popup-main {
        .popup-head {
          .popup-head-title {
            display: none;
          }
          .popup-close {
            width: 4rem !important;
            height: 3.6rem !important;
            background-color: #000000 !important;
            z-index: 999999 !important;
          }
        }
  
        .popup-content {
          padding: 0;
          width: 100%;
          height: 100%;
          position: absolute;
          background-color: #000000;
          iframe {
            width: 100%;
            height: 100%;
          }
         }
      }
    }
  
    // overlay
    .overlay {
      position: absolute;
      width: $full;
      height: $full;
      background: none !important;
    }
  
    // popup main
    .popup-main {
      min-width: 100%;
      position: absolute;
      right: 0;
      left: 0;
      margin: auto;
      z-index: 2;
      opacity: 0;
      max-width: 93%;
      z-index: 99;
  
      &.show {
        opacity: 1;
      }
  
      .popup-head {
        background-color: #000000;
  
        .popup-head-title {
          font-size: 1.3rem;
          color: $white;
          width: calc(100% - 3rem);
          padding: (1rem - .3rem) 1rem;
  
          i {
            margin-right: .5rem;
          }
        }
  
        .popup-close {
          position: absolute;
          right: 0;
          top: 0;
          z-index: 9;
          color: $white;
          cursor: pointer;
          font-size: 25px;
          width: 3rem;
          height: 2.65rem;
          background-color: lighten(#000000, 10%);
          display: inline-flex;
          justify-content: center;
          align-items: center;
          font-size: 1rem;
          @include easing();
  
          &:hover {
            background-color: darken(#000000, 5%);
          }
        }
      }
  
      .popup-content {
        padding: 1rem;
      }
  
      .popup-footer {
        padding: 1rem;
      }
    }
  }
  
  .popupContent {
    display: none;
  }
  
  