/* reset */

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {margin: 0;padding: 0;border: 0;font-size: 100%;vertical-align: baseline;}

/* HTML5 tags reset */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section{display: block;}

*, body, html {
  box-sizing: border-box;
  -webkit-box-sizing: border-box; 
}

/* states reset */
body{line-height: 1}
ol, ul{list-style: none;}
a {
  text-decoration: none;

  &:hover {
    text-decoration: none !important;
  }
}
blockquote, q {quotes: none}
table {border-collapse: collapse;border-spacing: 0;}

/* last added tags reset */
blockquote:before, blockquote:after,
q:before, q:after{ content: '';content: none;}

html{
  height: 100%;
  position: relative;
}

body {
  min-height: 100%;
}
