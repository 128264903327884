/**
animation
 */
@mixin easing($key:0.3s, $type: all, $animation:ease){
  transition: $type $key $animation;
  -moz-transition: $type $key $animation;
  -webkit-transition: $type $key $animation;
  -ms-transition: $type $key $animation;
}

@mixin borderRadius($key : 0.2rem){
  -webkit-border-radius: $key;
  -moz-border-radius: $key;
  -ms-border-radius: $key;
  border-radius: $key;
}
