// responsive

@mixin responsive($media){

    // xs = phone
    @if($media == xs) {
        @media screen and (max-width: $media-small) {
            @content;
        }
    }

    // sm = tablet and  big phone
    @elseif($media == sm) {
        @media (min-width: $media-small) and (max-width: $media-medium){
            @content;
        }
    }

    // md = small desktop
    @elseif($media == md) {
        @media (min-width: $media-medium) and (max-width: $media-large) {
            @content;
        }
    }

    // lg = normal desktop
    @elseif($media == lg) {
        @media (min-width: $media-large) {
            @content;
        }
    }

}
