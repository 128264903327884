@font-face {
  font-family: 'icomoon';
  src:  url('../../dist/fonts/icomoon.eot?enscfx');
  src:  url('../../dist/fonts/icomoon.eot?enscfx#iefix') format('embedded-opentype'),
  url('../../dist/fonts/icomoon.ttf?enscfx') format('truetype'),
  url('../../dist/fonts/icomoon.woff?enscfx') format('woff'),
  url('../../dist/fonts/icomoon.svg?enscfx#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

.icon {
  font-family: 'icomoon';
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  &.left-arrow:before {
    content: "\e903";
  }
  &.right-arrow:before {
    content: "\e904";
  }
  &.down-arrow:before { 
    content: "\e905";
  }
  &.full-screen:before {
    content: "\e900";
  }
  &.back-arrow:before {
    content: "\e901";
  }
  &.next-arrow:before {
    content: "\e902";
  }
  &.times:before {
    content: "\e906";
  }
}


